import { I18n } from 'i18n-js';
import {
  format as format_dateFns,
  formatDistanceToNow as formatDistanceToNow_dateFns,
} from 'date-fns';
import { es as esDateFns, enUS as enDateFns } from 'date-fns/locale';
import en from './en';
import es from './es';

export const ENGLISH = 'en';
export const SPANISH = 'es';

const translationMap = {
  en,
  es,
};

const dateFnsLocaleMap = {
  en: enDateFns,
  es: esDateFns,
};

export const LOCALE = {
  ENGLISH: 'en',
  SPANISH: 'es',
} as const;

type Locale = (typeof LOCALE)[keyof typeof LOCALE];

const browserLanguages = (navigator.languages as readonly Locale[]) || [
  navigator.language,
];
const stripRegionCode = (lang: string) =>
  lang.toLowerCase().split(/[_-]+/)[0] as Locale;
const browserLanguagesWeSupport = browserLanguages
  .map((lang) => stripRegionCode(lang))
  .filter((lang) => {
    return Object.keys(translationMap).includes(lang);
  });

const locale: Locale = browserLanguagesWeSupport[0] || LOCALE.ENGLISH;
export const dateFnsLocale = dateFnsLocaleMap[locale] || enDateFns;

const i18n = new I18n(translationMap);
i18n.locale = locale;
export const currentLocale = locale;

// Utility type to recursively extract all possible key paths
type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType]: ObjectType[Key] extends object
    ? `${Key & string}` | `${Key & string}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key & string}`;
}[keyof ObjectType];

// Derive the union type from the keys of the `en` object
export type TranslationKey = NestedKeyOf<typeof en>;
type InterpolationObject = Record<string, unknown>;

export const translate: (
  key: TranslationKey,
  interpolation?: InterpolationObject,
) => string = i18n.t.bind(i18n);

export function checkKeyExists(key: string) {
  return i18n.t(key, { locale: 'en' }) !== `[missing "en.${key}" translation]`;
}

/**
 * Utility function to remove any nested DOM nodes from a string
 * @param message the string to sanitize
 * @returns the sanitized string
 * @example
 * ```ts
 * const message = '<strong>Hello, world</strong>!';
 * const result = removeDOMNodesFrom(message);
 * console.info(result); // 'Hello, world!'
 */
export function removeDOMNodesFrom(messsage: string) {
  return messsage.replace(/<[^>]+>/g, '');
}

/**
 * Utility function to get the EN translation for a given key and will remove any nested DOM nodes
 * @param key the translation key
 * @returns English string or error message if key is missing
 */
export const getDefaultMessage = (key: TranslationKey): string => {
  const defaultMessage = i18n.t(key, { locale: 'en' });
  if (defaultMessage) {
    return removeDOMNodesFrom(defaultMessage);
  }
  console.error(`[missing "${key}" translation]`);
  return `[missing "${key}" translation]`;
};

// TODO add other date-fns wrappers as needed
export const formatDate: typeof format_dateFns = (date, formatStr, options) => {
  return format_dateFns(date, formatStr, {
    ...options,
    locale: dateFnsLocale,
  });
};

export const formatDistanceToNow: typeof formatDistanceToNow_dateFns = (
  date,
  options,
) => {
  return formatDistanceToNow_dateFns(date, {
    ...options,
    locale: dateFnsLocale,
  });
};
